import React from "react";
import ReactDOM from "react-dom/client";
import {
  createHashRouter,
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Faqs from "./pages/Faqs";
import Testimony from "./pages/Testimony";
import NotFound from "./components/NotFound";
import HomePage from "./pages/HomePage";
import WorkMode from "./pages/WorkMode";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/faqs",
        element: <Faqs />,
      },
      {
        path: "/how-we-work",
        element: <WorkMode />,
      },
      {
        path: "/testimonies",
        element: <Testimony />,
      },

      // {
      //   // Use Navigate for redirection
      //   path: "/contact",
      //   element: <Navigate to="/#/contact" />,
      // },
    ],
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
