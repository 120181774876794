import { useEffect, useRef } from "react";
import bitCoin from "../images/bitcards.jpg";

const About = (props) => {
  const desConRef = useRef({});
  let about = [
    {
      check: "fa-solid fa-check-double",
      textHeader: "24/7 Availability",
      dropCon: "fa-solid fa-angle-down absolute right-0 cursor-pointer",

      desCon: "clock",
      ref: "clock",
      textPara:
        "Yes, we are available every single minute of the day to trade your gift cards. This is a fact!",
    },
    {
      check: "fa-solid fa-check-double",
      textHeader: "Secure Transaction",
      dropCon: "fa-solid fa-angle-down absolute right-0 cursor-pointer",
      desCon: "lock ",

      textPara:
        "Trading with us is completely safe and secure. Your gift cards   are safe because we redeem them directly. We don't give out cards to third party.",
    },
    {
      check: "fa-solid fa-check-double",
      textHeader: "101% Satisfaction",
      dropCon: "fa-solid fa-angle-down  absolute right-0 cursor-pointer",
      desCon: "fa-solid fa-face-smile",
      textPara:
        "Maximum satisfaction is guaranteed when you trade with us. You will always come back for more.",
    },
    // {
    //   check: "fa-solid fa-check-double",
    //   textHeader: "World-className Customer Experience",
    //   dropCon: "fa-solid fa-angle-down absolute right-0 cursor-pointer",
    //   desCon: "fa-solid fa-thumbs-up",
    //   textPara:
    //     "Our team have been trained by professionals so as to provide you with top-notch customer experience. Every customer is treated fairly and with care.",
    // },
    {
      check: "fa-solid fa-check-double",
      textHeader: "Get Regular Updates",
      dropCon: "fa-solid fa-angle-down absolute right-0 cursor-pointer",
      desCon: "fa-solid fa-circle-info ",
      textPara:
        "Gift cards prices ain't stable. It's important you stay updated. We offer free daily price updates on WhatsApp and this helps our customers to know the best card to collect.",
    },
    // {
    //   check: "fa-solid fa-check-double",
    //   textHeader: "Customer Reviews",
    //   dropCon: "fa-solid fa-angle-down absolute right-0 cursor-pointer",
    //   desCon: "fa-solid fa-book",
    //   textPara:
    //     "We have over 7000+ customer with amazing reviews all over the internet. You can check some of them below",
    // },
  ];

  let aboutUs = about.map((x, ind) => {
    const { check, textHeader, dropCon, desCon, textPara } = x;
    return (
      <div className="relative  aboutTextWrapper" key={ind}>
        <div className="relative  about-text  " id={ind}>
          <div className="about-textHeader  ">
            <h3 className="header truncate text-center">{textHeader}</h3>
          </div>
        </div>
        <div className="write-up-wrapper flex relative px-5 pt-5 pb-9">
          <span className=" ">
            <div
              ref={(el) => (desConRef.current[ind] = el)}
              id={desCon}
              className={`${desCon}  aboutCons`}
            ></div>
          </span>
          <p className="write-up leading-relaxed tracking-wide  ">{textPara}</p>
        </div>
      </div>
    );
  });
  function addLock() {
    const lock = desConRef.current[1];

    setInterval(() => {
      if (lock.classList.contains("unlocked")) {
        lock.classList.remove("unlocked");
      } else {
        lock.classList.add("unlocked");
      }
    }, 3000);
  }

  useEffect(() => {
    addLock();
  }, []);

  return (
    <div id="about" className="about relative marginsTopAndBotton">
      <div className="aboutWrapper flex sm:flex-row flex-col items-center ">
        <div className="aboutHeader mx-8">
          <h2 className="text-center text-4xl font-semibold">About Us</h2>
          <p className="md:mt-5 md:mb-0  my-5 leading-relaxed tracking-wide  text-center">
            We deal with the best and offer the best market prices as you trust
            us with your digital assets.
          </p>
        </div>

        <div>
          <div className="about-textWrapper grid  sm:grid-cols-2">
            {aboutUs}
          </div>
        </div>
        {/* <div className="">
          <div className="aboutCardsWrapper   h-full text-end">
            <h3>{logoDisplay}</h3>
          </div>
        </div> */}
      </div>
      <div>
        <div className="btc">
          {/* <i class="btc1 h-9 w-9 object-contain rounded-full fa-solid fa-bitcoin-sign"></i> */}
          <img
            src={bitCoin}
            alt="bitcards 3d"
            className="absolute left-0 top-0 logoMage h-9 w-9 object-contain rounded-full  "
          />
        </div>
      </div>
    </div>
  );
};
export default About;
