import { Link, useLocation } from "react-router-dom";
import bitCoin from "../images/bitcards.jpg";
import { useContext, useEffect } from "react";
import { UserContext } from "../App";

const Contact = () => {
  const { handleChat } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/contact") {
      handleChat();
    }
  }, []);

  return (
    <div
      id="contact"
      className="contact relative text-white marginsTopAndBotton xl:pt-0 pt-10"
    >
      <div className="w-full text-center contactWrapper">
        <div className="">
          <h2 className=" text-4xl font-semibold">Contact Information</h2>
          <p className="text-md mx-1 mt-5">
            Our highly responsive customer support is available for you 247
          </p>
        </div>
        <div className="flex  mt-5 items-center justify-center  ">
          <div className="">
            <p className="mb-3">
              <Link to="mailto: dollamanwealth@gmail.com">
                <span className="text-md font-semibold">E-Mail: </span>
                dollamanwealth@gmail.com
              </Link>
            </p>
            <p className="mb-3  text-center ">
              <Link to="tel:+2348147343536">
                <span className="text-md font-semibold">Phone Call: </span>
                +2349034320059
              </Link>
            </p>
            <ul className="flex mb-3 justify-center items-center">
              <li className=" instaWrapper">
                <Link to="http://instagram.com/dollamanxchange">
                  <i className="fa-brands fa-instagram"></i>
                </Link>
              </li>
              <li className="mx-5 fbWrapper">
                <Link to="https://web.facebook.com/profile.php?id=100088205514348 ">
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
              </li>
              <li className="twitWrapper">
                <Link to="http://Twitter.com/dolapo_ak">
                  <i className="fa-brands fa-twitter"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="btc z-[-10]">
        {/* <i class="btc1 h-9 w-9 object-contain rounded-full fa-solid fa-bitcoin-sign"></i> */}
        <img
          src={bitCoin}
          alt="bitcards 3d"
          className="absolute   left-[0px] top-[0px] logoMage h-9 w-9 object-contain rounded-full  "
        />
      </div>
    </div>
  );
};
export default Contact;
