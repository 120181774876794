import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../App";

const Chat = (props) => {
  const { handleChat } = props;
  const toggleChat = props.toggleChat;

  const { openChat } = useContext(UserContext);

  // console.log(x());

  useEffect(() => {
    // if (window.location.pathname === "/contact") {
    //   setOpenChat(!openChat);
    // }
  }, []);

  return (
    <div className="chat flex flex-col">
      <Link to="mailto: dollamanwealth@gmail.com">
        <i
          className={`${
            openChat ? "showmailChatCons" : ""
          } mailCon  bi bi-envelope-at`}
        ></i>
      </Link>
      {/* showmailChatCons */}
      {/* showWhatsappChatCons */}
      <Link to="https://wa.link/434i4e" className="font-semibold text-white ">
        <i
          className={` ${
            openChat ? "showWhatsappChatCons" : ""
          } whatsappCon bi bi-whatsapp`}
        ></i>
      </Link>
      <i onClick={handleChat} className=" chatCon bi bi-chat-dots"></i>
    </div>
  );
};

export default Chat;
