import { useEffect, useRef, useState } from "react";
import vid from "../images/flower.mp4";
import img10 from "../images/owner.jpg";
import img11 from "../images/partner.jpg";
import img13 from "../images/partner2.jpg";

import img12 from "../images/Artist.jpg";
import cover from "../images/cover.png";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";

const Testimony = (props) => {
  const [timer, setTimer] = useState(null);
  const index = useRef(0);

  const swipeData = [
    {
      text: "I have been using Dolla.manXchange for more than a year without any issues.",
      img: img10,
      owner: "Bhoco",
      title: "Business Owner",
      id: 0,
    },
    {
      text: "Trade with Dolla.manXchange to be sure your money is safe for instant cash.",
      img: img13,
      owner: "Abdulsalam",
      title: "Supplier & Co-partner",
      id: 1,
    },
    {
      text: "Trade with Dolla.manXchange to be sure your money is safe for instant cash.",
      img: img11,
      owner: "Abdulmalik",
      title: "Supplier & Co-partner",
      id: 2,
    },
    {
      text: "If you have cryptocurrencies and giftcards, Dolla.manXchange is 101% to trade for instant cash. ",
      img: img12,
      owner: "YLEODIXY",
      title: "Artist & Music Producer",
      id: 3,
    },
  ];
  useEffect(() => {
    document.querySelector(".fa-play").style.display = "none";
    document.getElementById("cover").style.display = "none";

    document.querySelectorAll(".video").forEach(function (video) {
      video.parentNode.addEventListener("click", function () {
        if (video.paused) {
          video.play();
          this.querySelector(".fa-play").style.display = "none";
          document.getElementById("cover").style.display = "none";
        } else {
          video.pause();
          this.querySelector(".fa-play").style.display = "block";
          document.getElementById("cover").style.display = "block";
        }
      });
      document.getElementById("myVideo").addEventListener("ended", function () {
        document.querySelector(".fa-play").style.display = "block";
        document.getElementById("cover").style.display = "block";
      });
    });

    // Autoplay video on mount
    const videoElement = document.getElementById("myVideo");
    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", function () {
        this.muted = true; // Mute the video for autoplay
        this.play();
        if (this.querySelector(".fa-play") !== null) {
          this.querySelector(".fa-play").style.display = "none";
        }
      });
    }
  }, []);

  return (
    <div className="testimony relative marginsTopAndBotton">
      <h2 className=" testimony-header text-center text-4xl font-semibold">
        What our customers say about us
      </h2>
      <div className="lg:grid grid-cols-2  gap-10 flex   lg:flex-row flex-col testimony-content">
        <Swiper
          modules={[Navigation, Autoplay, Pagination]}
          className="w-full"
          spaceBetween={0}
          slidesPerView={1}
          autoplay
          loop={true}
          pagination={{ clickable: true }}
        >
          {swipeData.map((item, index) => (
            <SwiperSlide key={index}>
              <p className="text">{item.text}</p>
              <div className="images owner-wrapper pb-5 flex">
                <img className="mage" src={item.img} alt="" />
                <div className="">
                  <h4 className="owner mt-2 text-sm font-semibold">
                    {item.owner}
                  </h4>
                  <p className="title text-sm">{item.title}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="video-wrapper  ">
          <img src={cover} alt="" className="cover" id="cover" />
          <video autoPlay playsInline className="video  " id="myVideo">
            <source src="/media/cc0-videos/flower.webm" type="video/webm" />
            <source src={vid} type="video/mp4" />
            Download the
            <a href="/media/cc0-videos/flower.webm">WEBM</a>
            or
            <a href="/media/cc0-videos/flower.mp4">MP4</a>
            video.
          </video>
          <div className="playpause">
            <i className="fa-solid fa-play"></i>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testimony;
