import Navbar from "./components/Navbar";
import Chat from "./components/Chat";
import Footer from "./components/Footer";

import { Outlet } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const UserContext = createContext();
function App() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      document.title = `Dmxchange/home`;
    } else {
      document.title = `Dmxchange${location.pathname}`;
    }
  }, [location.pathname]);

  const [openChat, setOpenChat] = useState(false);
  const handleChat = () => {
    if (openChat === true) {
      setOpenChat(false);
    } else {
      setOpenChat(true);
    }
  };

  return (
    <div className="App   mx-5  overflow-x-hidden">
      <UserContext.Provider value={{ openChat, handleChat }}>
        <Navbar />

        <Outlet />
        <Chat handleChat={handleChat} />
      </UserContext.Provider>

      <Footer />
    </div>
  );
}

export default App;
