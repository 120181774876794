// import { Link } from "react-router-dom";
// import logo from "../images/logo(1).jpg";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer mb-9 ">
      <div className="w-full  flex  justify-center">
        <div className="line mt-9  "></div>
      </div>
      <p className="text-center">
        &copy; {currentYear} DollaMan Xchanger. All Rights Reserved
      </p>
    </div>
  );
};
export default Footer;
