import bitCoin from "../images/bitcards.jpg";

const WorkMode = (props) => {
  const workCombine = [
    {
      workCon: "fa-solid fa-address-book",
      title: "Chat Us Up",
      para: " Chat us up and indicate you want to trade. We'll respond with the current rate, if you accept we proceed to the next step.",
    },
    {
      workCon: "fas fa-check",
      title: "Provide The Gift Card",
      para: " You are required to send us a clear picture of your gift card. As direct loaders and dealers, it takes us just few seconds to confirm your gift cards.",
    },

    {
      workCon: "fa-solid fa-shield",
      title: "Admin Verification",
      para: " Once your product is being recieved, admin verifies whether your product is authentic.",
    },
    {
      workCon: "fa-solid fa-pause",
      title: "Wait For Confirmation",
      para: " Wait for your transaction to be processed, this usually takes less than 5 minutes. Once your card has been redeemed, send your bank account.",
    },
    {
      workCon: "fa-solid fa-money-check-dollar",
      title: "Get Paid",
      para: "Wait for payment to you, this is usually less than 5 minutes. Pretty easy right? Now begin trading like a boss.",
    },
  ];

  const work = [
    {
      workCon: "fa-solid fa-address-book",
      title: "Chat Us Up",
      para: " Chat us up and indicate you want to trade. We'll respond with the current rate, if you accept we proceed to the next step.",
    },
    {
      workCon: "fas fa-check",
      title: "Provide The Gift Card",
      para: " You are required to send us a clear picture of your gift card. As direct loaders and dealers, it takes us just few seconds to confirm your gift cards.",
    },

    {
      workCon: "fa-solid fa-shield",
      title: "Admin Verification",
      para: " Once your product is being recieved, admin verifies whether your product is authentic.",
    },
  ];
  const workColTwo = [
    {
      workCon: "fa-solid fa-pause",
      title: "Wait For Confirmation",
      para: " Wait for your transaction to be processed, this usually takes less than 5 minutes. Once your card has been redeemed, send your bank account.",
    },
    {
      workCon: "fa-solid fa-money-check-dollar",
      title: "Get Paid",
      para: "Wait for payment to you, this is usually less than 5 minutes. Pretty easy right? Now begin trading like a boss.",
    },
  ];

  const workMode = work.map((item, ind) => {
    const { workCon, title, para } = item;

    return (
      <div className="cont-Wrapper   flex items-center flex-col" key={ind}>
        <div className="workCon bg-slate-200">
          <i className={` ${workCon} text-center `}></i>
        </div>
        <h4 className="text-center my-3 text-xl font-semibold">{title}</h4>
        <p className="text-center text-md">{para}</p>
      </div>
    );
  });
  const workModeTwo = workColTwo.map((item, ind) => {
    const { workCon, title, para } = item;

    return (
      <div className="cont-Wrapper  flex items-center flex-col " key={ind}>
        <div className="workCon bg-slate-200">
          <i className={` ${workCon} text-center `}></i>
        </div>
        <h4 className="text-center my-3 text-xl font-semibold">{title}</h4>
        <p className=" text-center text-md">{para}</p>
      </div>
    );
  });
  const workCombineLgBelow = workCombine.map((item, ind) => {
    const { workCon, title, para } = item;

    return (
      <div className="cont-Wrapper  flex items-center flex-col " key={ind}>
        <div className="workCon bg-slate-200">
          <i className={` ${workCon} text-center `}></i>
        </div>
        <h3 className="text-center my-3 text-xl font-semibold">{title}</h3>
        <p className=" text-center text-md">{para}</p>
      </div>
    );
  });

  return (
    <div className="workMode pt-10 relative marginsTopAndBotton">
      <div>
        <h2 className="text-center text-4xl font-semibold"> How we work</h2>
        <p className="md:mt-5 md:mb-0  my-5 leading-relaxed tracking-wide   text-center">
          Are you wondering about how we operate?
        </p>
      </div>
      <div className="">
        <div className="lg:hidden  workCombineLgBelow grid lg:grid-cols-3 sm:grid-cols-2 ">
          {workCombineLgBelow}
        </div>
        <div className="hidden lg:grid">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 ">{workMode}</div>
          <div className="workModeTwo mt-11 flex items-center justify-around ">
            {workModeTwo}
          </div>
        </div>
      </div>
      <div className="btc mr-2">
        {/* <i class="btc1 h-9 w-9 object-contain rounded-full fa-solid fa-bitcoin-sign"></i> */}
        <img
          src={bitCoin}
          alt="bitcards 3d"
          className="absolute right-0 top-0 logoMage h-9 w-9 object-contain rounded-full  "
        />
      </div>
    </div>
  );
};
export default WorkMode;
