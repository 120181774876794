import { Link, useRouteError } from "react-router-dom";

const NotFound = () => {
  const error = useRouteError();
  return (
    <div id="error-page " className="m-5">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message} </i>
        go back to{" "}
        <Link to={"/"} className="  relative" id="active">
          {/* <span class="animate-ping  linksClass"></span> */}
          <span className=" custom-btn btn-5 text-blue-500">Home</span>
        </Link>
      </p>
    </div>
  );
};

export default NotFound;
