import { useEffect, useRef, useState } from "react";
import bitCoin from "../images/bitcards.jpg";

const Faqs = (props) => {
  const [logoDisplay, setlogoDisplay] = useState("");
  const [rot, setRot] = useState(0);
  const [open, setOpen] = useState(0);

  let logos = [
    {
      logo: "faqsCards fa-brands fa-apple",
    },
    {
      logo: "faqsCards fa-brands fa-steam",
    },
    {
      logo: "faqsCards fa-brands fa-amazon",
    },
    {
      logo: "faqsCards fa-brands fa-ebay",
    },
  ];

  let about = [
    {
      check: "fa-solid fa-check-double",
      textHeader: "What is the present rate on Crypto and Gift cards? ",
      dropCon: "fa-solid fa-angle-down absolute right-0 cursor-pointer",

      desCon: "fa-solid fa-gift",
      textPara:
        "Rates vary daily and based on the amount of crypto being sold. Kindly refer to our social media page for daily rate updates on gift cards and crypto or click the trade button to speak with our customer service and get the exact rate for your trades.",
    },

    {
      check: "fa-solid fa-check-double",
      textHeader: "How can I trust you? ",
      dropCon: "fa-solid fa-angle-down absolute right-0 cursor-pointer",
      desCon: "fa-solid fa-handshake",
      textPara:
        "DollaMan Xchange is a registered financial service provider. With over 19k+ repeat customers monthly and 4 years of operation, we have been able to establish a reputation as a reliable and trustworthy exchange platform.",
    },
    {
      check: "fa-solid fa-check-double",
      textHeader: "How long does payment take? ",
      dropCon: "fa-solid fa-angle-down  absolute right-0 cursor-pointer",
      desCon: "fa-solid fa-receipt",
      textPara: " Payments are processed instantly.",
    },
    {
      check: "fa-solid fa-check-double",
      textHeader: "What should I send when I want to sell a gift card? ",
      dropCon: "fa-solid fa-angle-down absolute right-0 cursor-pointer",
      desCon: "fa-solid fa-thumbs-up",
      textPara:
        "Depending on the card we might need any of the following; Receipts, Country or first 4 Digits of the card.",
    },
    {
      check: "fa-solid fa-check-double",
      textHeader: "How will I get my payment? ",
      dropCon: "fa-solid fa-angle-down absolute right-0 cursor-pointer",
      desCon: "fa-solid fa-piggy-bank",
      textPara:
        " Payments will be processed into the customer’s desired account upon completion and confirmation of trade.",
    },
  ];
  const handleHeight = (e, ind) => {
    setOpen(ind);

    if (e.currentTarget.classList.contains("addFaqsHeight")) {
      setOpen(null);
      setRot(null);
    } else {
      setOpen(ind);
      setRot(ind);
    }
  };

  function odd() {
    const oddAbout = about.filter((x, ind) => ind % 2 !== 1);
    return oddAbout;
  }
  function even() {
    const evenAbout = about.filter((x, ind) => ind % 2 === 1);
    return evenAbout;
  }

  let faqsOdd = odd().map((x, ind) => {
    const { check, textHeader, dropCon, desCon, textPara } = x;

    return (
      <div
        onClick={(e) => handleHeight(e, ind)}
        className={`relative  faqsTextWrapper ${
          open === ind ? "addFaqsHeight" : ""
        }`}
        key={ind}
      >
        <div
          className="relative  faqs-text "
          // ref={ref}
          id={ind}
        >
          <div className="faqs-textHeader  ">
            <i className={`ms-5 ${check}`}></i>
            <h3 className="header truncate ">{textHeader}</h3>
            <i
              className={`${dropCon} ${rot === ind ? "rot-fa-angle-down" : ""}`}
            ></i>
          </div>
        </div>
        <div className="write-up-wrapper flex">
          <span className=" ">
            <i className={desCon}></i>
          </span>
          <p className="write-up leading-relaxed tracking-wide  ">{textPara}</p>
        </div>
      </div>
    );
  });
  let faqsEven = even().map((x, ind) => {
    const { check, textHeader, dropCon, desCon, textPara } = x;
    return (
      <div
        onClick={(e) => handleHeight(e, ind + odd().length)}
        className={`relative  faqsTextWrapper ${
          open === ind + odd().length ? "addFaqsHeight" : ""
        }`}
        key={ind}
      >
        <div
          className="relative  faqs-text  "
          // ref={ref}
          id={ind}
        >
          <div className="faqs-textHeader  ">
            <i className={`ms-5 ${check}`}></i>
            <h3 className="header truncate ">{textHeader}</h3>
            <i
              className={`${dropCon} ${
                rot === ind + odd().length ? "rot-fa-angle-down" : ""
              }`}
            ></i>
          </div>
        </div>
        <div className="write-up-wrapper flex">
          <span className=" ">
            <i className={desCon}></i>
          </span>
          <p className="write-up leading-relaxed   ">{textPara}</p>
        </div>
      </div>
    );
  });

  let combineFaqs = about.map((x, ind) => {
    const { check, textHeader, dropCon, desCon, textPara } = x;
    return (
      <div
        onClick={(e) => handleHeight(e, ind)}
        className={`relative  faqsTextWrapper ${
          open === ind ? "addFaqsHeight" : ""
        }`}
        key={ind}
      >
        <div
          className="relative  faqs-text  "
          // ref={ref}
          id={ind}
        >
          <div className="faqs-textHeader  ">
            <i className={`ms-5 ${check}`}></i>
            <h3 className="header truncate ">{textHeader}</h3>
            <i
              className={`${dropCon} ${rot === ind ? "rot-fa-angle-down" : ""}`}
            ></i>
          </div>
        </div>
        <div className="write-up-wrapper flex">
          <span className=" ">
            <i className={desCon}></i>
          </span>
          <p className="write-up leading-relaxed   ">{textPara}</p>
        </div>
      </div>
    );
  });

  useEffect(() => {
    changeLogo();
  }, []);

  // Fuction to change logos

  function changeLogo() {
    let i = 3;
    function count() {
      let singleCard = logos.filter((x, y) => y === i);

      setlogoDisplay(
        singleCard.map((x, ind) => {
          const { logo } = x;
          return <i className={logo} key={ind}></i>;
        })
      );

      i--;

      if (i === -1) {
        i = 3;
      }
    }
    let timerId = setInterval(count, 3000);
    // setTimeout(() => {
    //   clearInterval(timerId);
    // }, 4000);
    // logoDisplay = count();
    count();
  }

  return (
    <div id="faqs" className="faqs relative  marginsTopAndBotton">
      <div className="flex flex-row items-center justify-center">
        <h2 className=" text-4xl font-semibold truncate mr-3">
          Frequently Asked Questions
        </h2>

        <div className="faqsCardsWrapper ml-auto   h-full text-end">
          <h3 className="">{logoDisplay}</h3>
        </div>
      </div>
      <div className="faqsWrapper flex lg:flex-row flex-col  gap-5">
        <div className="faqsWriteCont lg:hidden block ">
          <div className=" faqs-textWrapper flex flex-col gap-5">
            {combineFaqs}
          </div>
        </div>

        <div className="faqsWriteCont  lg:block hidden ">
          <div className=" faqs-textWrapper flex flex-col gap-5">{faqsOdd}</div>
        </div>
        <div className="faqsWriteCont lg:block hidden ">
          <div className=" faqs-textWrapper flex flex-col gap-5">
            {faqsEven}
          </div>
        </div>
      </div>
      <div className="btc mr-2">
        {/* <i class="btc1 h-9 w-9 object-contain rounded-full fa-solid fa-bitcoin-sign"></i> */}
        <img
          src={bitCoin}
          alt="bitcards 3d"
          className="absolute right-0 bottom-0 logoMage h-9 w-9 object-contain rounded-full  "
        />
      </div>
    </div>
  );
};

export default Faqs;
