import { useEffect, useState } from "react";
import { Link, useLocation, useRoutes } from "react-router-dom";
// import logo from "../images/dollaman-d-dark.png";
import logo from "../images/DOLLA MAN EXCHANGE FULL LOGO.png";

// import { scroller } from "react-scroll";
//
const Navbar = (props) => {
  // const { handleClick } = props;

  // const location = useLocation();
  // const pathname = location.pathname;

  const [hideNav, setHideNav] = useState(true);

  // console.log(pathname);

  function toggleNav() {
    setHideNav(!hideNav);
    // const showNav = document.querySelector(".nav-wrapper");
    // const menu = document.querySelector(".menu");
    // const arrowRot = document.querySelector(".arrowRot");

    // arrowRot.classList.toggle("arrow_rot");

    // if (showNav.classList.contains("hide-nav")) {
    //   showNav.classList.remove("hide-nav");
    //   menu.classList.add("menuTog");
    //   document.body.classList.add("adjustScroll");
    // } else {
    //   showNav.classList.add("hide-nav");
    //   menu.classList.remove("menuTog");
    //   document.body.classList.remove("adjustScroll");
    // }
  }
  const handleClick = () => {
    toggleNav();
  };

  // function scrollerOnClick(x, offsetNum) {
  //   if (x === "/") {
  //     scroller.scrollTo("homePage", {
  //       duration: 700,
  //       delay: 0,
  //       smooth: true,
  //       offset: offsetNum, // adjust the offset value as needed
  //     });
  //   }
  //   if (x === "/about") {
  //     scroller.scrollTo("about", {
  //       duration: 700,
  //       delay: 0,
  //       smooth: true,
  //       offset: offsetNum, // adjust the offset value as needed
  //     });
  //   }
  //   if (x === "/testimonies") {
  //     // referenceTestimony.current?.scrollIntoView({ behavior: "smooth" });
  //     scroller.scrollTo("testimony", {
  //       duration: 700,
  //       delay: 0,
  //       smooth: true,
  //       offset: offsetNum, // adjust the offset value as needed
  //     });
  //   }
  //   if (x === "/faqs") {
  //     scroller.scrollTo("faqs", {
  //       duration: 700,
  //       delay: 0,
  //       smooth: true,
  //       offset: offsetNum, // adjust the offset value as needed
  //     });
  //   }
  //   if (x === "/contact") {
  //     scroller.scrollTo("contact", {
  //       duration: 700,
  //       delay: 0,
  //       smooth: true,
  //       offset: offsetNum, // adjust the offset value as needed
  //     });
  //     // toggleChatOnLink();
  //   }
  //   if (x === "/how-we-work") {
  //     scroller.scrollTo("workMode", {
  //       duration: 700,
  //       delay: 0,
  //       smooth: true,
  //       offset: offsetNum, // adjust the offset value as needed
  //     });
  //   }
  // }

  // function removeNavOnMobile() {
  //   const menu = document.querySelector(".menu").childNodes;
  //   menu.forEach((item) => {
  //     item.lastElementChild.addEventListener("click", () => {
  //       const pathName = item.lastElementChild.pathname;
  //       toggleNav();
  //       if (window.matchMedia("(max-width: 1280px)").matches) {
  //         // scrollerOnClick(pathName, -400);
  //       } else {
  //         // scrollerOnClick(pathName, -100);
  //       }
  //     });
  //   });
  // }

  // useEffect(() => {
  //   removeNavOnMobile();
  // }, []);

  const handleShowMenu = (e) => {
    toggleNav();
  };

  return (
    <div className="sticky top-0 navbar  ">
      <div
        className={`nav-wrapper ${
          hideNav ? "hide-nav " : null
        } flex relative  xl:items-center justify-between mt-4 `}
      >
        <div className="logo  ">
          <img
            className="h-[90px] w-[200px]  swing object-contain"
            src={logo}
            alt="logo"
          />
          {/* <span className="best text-md italic tracking-wider">
            We serve you with the best!
          </span> */}
          {/* <span className="font-semibold text-2xl tracking-wider logoText">
            olla.manXchange
          </span>
          <span className="best text-md italic tracking-wider">
            We deal with the best!
          </span> */}
        </div>
        <div className="menu-cont xl:text-left  text-right links flex justify-between xl:flex-row flex-col ">
          <ul
            className={`menu ${
              !hideNav ? "menuTog" : null
            }  font-semibold handleActive text-[#413314]   flex   xl:items-center items-end 
            xl:flex-row flex-col mr-2`}
            // onClick={handleClick}
          >
            <li className="  relative xl:mr-8 xl:my-0 mb-4 ">
              <Link
                onClick={handleClick}
                to="/"
                className=" linksClass relative"
                id="active"
              >
                <span class="animate-ping active "></span>
                <span className=" custom-btn btn-5">Home</span>
              </Link>
            </li>
            <li className="relative    xl:mx-8 xl:my-0 mb-4">
              <Link
                onClick={handleClick}
                to="/how-we-work"
                className="linksClass relative "
              >
                <span class="animate-ping "></span>
                <span className=" custom-btn btn-5"> How we work</span>
              </Link>
            </li>
            <li className="relative    xl:mx-8 xl:my-0 mb-4 ">
              <Link
                onClick={handleClick}
                to="/about"
                className="linksClass relative"
                // onClick={handleScrollToAbout}
              >
                <span class="animate-ping "></span>

                <span className=" custom-btn btn-5">About Us</span>
              </Link>
            </li>
            <li className="relative xl:mx-8 xl:my-0 mb-4 ">
              <Link
                onClick={handleClick}
                className="linksClass relative"
                to="/testimonies"
              >
                <span class="animate-ping "></span>

                <span className=" custom-btn btn-5">Testimonials</span>
              </Link>
            </li>
            <li className="relative    xl:mx-8 xl:my-0 mb-4 ">
              <Link
                onClick={handleClick}
                // onClick={handleScrollToContact}
                to="/contact"
                className="linksClass relative"
              >
                <span class="animate-ping "></span>

                <span className=" custom-btn btn-5">Contact</span>
              </Link>
            </li>

            <li className="relative    xl:ml-8 xl:my-0 mb-4 ">
              <Link
                onClick={handleClick}
                to="/faqs"
                // onClick={handleScrollToFaqs}
                className="linksClass relative"
              >
                <span class="animate-ping "></span>

                <span className=" custom-btn btn-5  ">Faqs</span>
              </Link>
            </li>
          </ul>
        </div>
        <div
          className="xl:hidden   arrow-down arrow  p-7 w-6 h-6  flex justify-center items-center  round-6 text-xl   rounded-full cursor-pointer "
          onClick={handleShowMenu}
        >
          <i class="fa-solid fa-bars text-[#f2b830] text-3xl  arrowRot"></i>
        </div>
      </div>
      <div className=" hidden">
        <div className="xl:block hidden scroll-text text-white whitespace-nowrap  text-black">
          Hey there! we are always active for trades. We buy all kinds of
          giftcards for all countries, BTC/USDT, and all funds at the best
          market rate. Payment is instant, with no stress and no stories.
        </div>
      </div>
    </div>
  );
};
export default Navbar;
