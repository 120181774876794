import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";

import img1 from "../images/card 1.png";
import img2 from "../images/card 2.png";
import img3 from "../images/card 3.png";
import img4 from "../images/card 4.png";
import img5 from "../images/card 5.png";
import img6 from "../images/card 6.png";
import img7 from "../images/card 7.png";

const imgArr = [img1, img2, img3, img4, img5, img6, img7];

const Cards = () => {
  return (
    <div className="bg-[#fff] py-5">
      <Swiper
        modules={[Navigation, Autoplay, Pagination]}
        className=""
        autoplay
        spaceBetween={15}
        loop={true}
        lazy={true}
        pagination={{ clickable: true }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2.19,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3.2,
          },
          // when window width is >= 640px
          730: {
            slidesPerView: 3.8,
          },

          1024: {
            slidesPerView: 4.2,
          },
          // ... add more breakpoints as needed
        }}
      >
        {imgArr.map((img, ind) => {
          return (
            <SwiperSlide className="cardWrapper py-2" key={ind}>
              <img
                src={img}
                alt="gift card image"
                className="giftCards sm:rounded-2xl rounded-xl shadow-sm  border-r-2   border-b-2 "
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
export default Cards;
