import { Link } from "react-router-dom";
import QR from "../images/qr (2).jpg";

import Cards from "../components/Cards";
import WorkMode from "./WorkMode";
import About from "./About";
import Testimony from "./Testimony";
import Contact from "./Contact";
import Faqs from "./Faqs";
import { UserContext } from "../App";
import { useContext, useEffect } from "react";

const HomePage = () => {
  // let resizeTimer;
  // window.addEventListener("resize", () => {
  //   document.body.classNameList.add("resize-animation-stopper");
  //   clearTimeout(resizeTimer);
  //   resizeTimer = setTimeout(() => {
  //     document.body.classNameList.remove("resize-animation-stopper");
  //   }, 400);
  // });

  return (
    <div id="homePage" className=" homePage  relative  z-0 ">
      <div className="  flex flex-col  ">
        <div className="textWrapper mt-7 w-3/4  flex flex-col justify-start items-start">
          <h1 className="leading-10 font-bold  text-5xl ">
            {/* Welcome to DollaMan Wealth hhh*/}
            Start trading without borders
          </h1>
          <p className=" xl:text-md  leading-normal mt-5 mb-3 ">
            {/* DollaManXchange is your one quick-stop platform to trade your
            Digital assets and currencies exchange for instant cash or
            crypto payment today. */}
            DMX is your quick-stop trading platform to trade up to 360
            Cryptocurrencies, Digital assets, and funds as we help trade safely
            from anywhere around the world at the best market exchange rate for
            Instant cash or cryptocurrency earnings.
          </p>
          <div className="relative">
            <span className="animate-ping active absolute left-0"></span>
          </div>
          <div className="whatsAppBtn flex flex-wrap  w-full">
            <button className="custom-btn btn-12 tradeBtn">
              <Link
                to="https://wa.link/434i4e"
                className="font-semibold text-white "
              >
                <span>Contact to Trade</span>
                <span className="whitespace-nowrap">Contact to trade</span>
              </Link>
            </button>

            <button className="custom-btn btn-12 tradeBtn tradeBtn2 ml-10 sm:ml-14">
              <Link
                to="https://wa.link/0lxz0b"
                className="font-semibold text-white "
              >
                <span>Click to Trade</span>
                <span className="whitespace-nowrap ">Contact to trade</span>
              </Link>
            </button>
          </div>
        </div>

        {/* {<GiftCards />} */}
      </div>
      <div className="QRcode mt-3  absolute  text-center top-0 right-0">
        <div className="relative">
          <span className="animate-ping active absolute right-0"></span>
        </div>
        <p className="text-sm">Scan to Trade</p>
        <img className="h-20 w-20" src={QR} alt="QR code" />
      </div>
      <div>
        <div className="btc opacity-[0.1]">
          <i className="btc1 h-9 w-9 object-contain rounded-full fa-solid fa-bitcoin-sign"></i>
        </div>
        <div className="btc opacity-[0.1]">
          <i className="btc2 h-9 w-9 object-contain rounded-full fa-solid fa-bitcoin-sign"></i>
        </div>
        <div className="btc opacity-[0.1]">
          <i className="btc3 h-9 w-9 object-contain rounded-full fa-solid fa-bitcoin-sign"></i>
        </div>
      </div>
      <Cards />
      <WorkMode />
      <About />
      <Testimony />
      <Contact />
      <Faqs />
    </div>
  );
};
export default HomePage;
